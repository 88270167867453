export default function ShieldIcon(props) {
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.63 3.65031C19.5138 3.55633 19.3781 3.48958 19.2327 3.45497C19.0873 3.42036 18.9361 3.41877 18.79 3.45031C17.7214 3.67425 16.6183 3.67711 15.5486 3.45869C14.4789 3.24028 13.4653 2.80522 12.57 2.18031C12.4026 2.06418 12.2037 2.00195 12 2.00195C11.7963 2.00195 11.5974 2.06418 11.43 2.18031C10.5348 2.80522 9.52108 3.24028 8.45137 3.45869C7.38166 3.67711 6.27857 3.67425 5.21001 3.45031C5.06394 3.41877 4.91267 3.42036 4.76731 3.45497C4.62194 3.48958 4.48618 3.55633 4.37001 3.65031C4.25399 3.74443 4.16053 3.86334 4.0965 3.99832C4.03247 4.1333 3.9995 4.28091 4.00001 4.43031V11.8803C3.99912 13.3141 4.34078 14.7273 4.99654 16.0023C5.6523 17.2773 6.60319 18.3771 7.77001 19.2103L11.42 21.8103C11.5894 21.9309 11.7921 21.9957 12 21.9957C12.2079 21.9957 12.4106 21.9309 12.58 21.8103L16.23 19.2103C17.3968 18.3771 18.3477 17.2773 19.0035 16.0023C19.6592 14.7273 20.0009 13.3141 20 11.8803V4.43031C20.0005 4.28091 19.9675 4.1333 19.9035 3.99832C19.8395 3.86334 19.746 3.74443 19.63 3.65031ZM18 11.8803C18.0008 12.9951 17.7353 14.0939 17.2257 15.0853C16.716 16.0768 15.977 16.9322 15.07 17.5803L12 19.7703L8.93001 17.5803C8.02304 16.9322 7.28399 16.0768 6.77435 15.0853C6.26472 14.0939 5.99924 12.9951 6.00001 11.8803V5.58031C8.09643 5.75974 10.196 5.27333 12 4.19031C13.804 5.27333 15.9036 5.75974 18 5.58031V11.8803Z"
        fill={props.fill ?? "currentColor"}
      />
    </svg>
  );
}

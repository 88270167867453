export default function LightBulbIcon(props) {
  return (
    <svg
      width={props.width ?? "24"}
      height={props.height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.45 12.4598C18.6176 11.0222 19.1679 9.18062 18.9804 7.33821C18.793 5.49579 17.8831 3.80273 16.45 2.62976C15.629 1.97175 14.6718 1.50473 13.6478 1.26258C12.6239 1.02043 11.5588 1.00922 10.53 1.22976C9.37487 1.48526 8.30333 2.02936 7.41546 2.81124C6.5276 3.59313 5.85239 4.58729 5.45291 5.70086C5.05342 6.81444 4.94268 8.0111 5.13104 9.17907C5.3194 10.347 5.80071 11.4482 6.53002 12.3798C7.1947 13.1884 7.57428 14.1936 7.61002 15.2398V15.5298C7.22951 15.8974 7.01017 16.4008 7.00002 16.9298V18.9298C7.00002 19.4602 7.21073 19.9689 7.58581 20.344C7.96088 20.719 8.46959 20.9298 9.00002 20.9298V21.9298C9.00002 22.195 9.10538 22.4493 9.29291 22.6369C9.48045 22.8244 9.7348 22.9298 10 22.9298C10.2652 22.9298 10.5196 22.8244 10.7071 22.6369C10.8947 22.4493 11 22.195 11 21.9298V20.9298H13V21.9298C13 22.195 13.1054 22.4493 13.2929 22.6369C13.4804 22.8244 13.7348 22.9298 14 22.9298C14.2652 22.9298 14.5196 22.8244 14.7071 22.6369C14.8947 22.4493 15 22.195 15 21.9298V20.9298C15.5305 20.9298 16.0392 20.719 16.4142 20.344C16.7893 19.9689 17 19.4602 17 18.9298V16.9298C17.0005 16.4065 16.7958 15.9039 16.43 15.5298V15.0998C16.4592 14.1292 16.8191 13.1978 17.45 12.4598ZM9.00002 18.9298V16.9298H15V18.9298H9.00002ZM15.89 11.2098C15.0048 12.2538 14.4913 13.5623 14.43 14.9298H9.56002C9.46569 13.5443 8.94131 12.2229 8.06002 11.1498C7.53063 10.4854 7.17924 9.6971 7.039 8.85926C6.89876 8.02142 6.97431 7.16167 7.2585 6.36112C7.54269 5.56058 8.02617 4.84564 8.6633 4.28377C9.30044 3.72189 10.0702 3.33162 10.9 3.14976C11.6352 2.98393 12.3984 2.98644 13.1325 3.15711C13.8666 3.32779 14.5526 3.6622 15.1392 4.13536C15.7258 4.60851 16.1979 5.20816 16.5202 5.88947C16.8424 6.57078 17.0064 7.31612 17 8.06976C17.0063 9.21364 16.6138 10.324 15.89 11.2098Z"
        fill={props.fill ?? "currentColor"}
      />
    </svg>
  );
}
